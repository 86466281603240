/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import TopHeader from './Page/TopHeader/TopHeader';
import './layout.css';

const Layout = ({ children }) => {
  return (
    <>
      <Container fluid>
        <Grid padded>
          <TopHeader siteTitle="HistoryCity" />
          {children}
        </Grid>
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
