import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { keyBy } from 'lodash';
import { Grid, Header, Dropdown } from 'semantic-ui-react';

import Logo from '../../../images/svg/logo.svg';

import './TopHeader.css';

const TopHeader = ({ siteTitle }) => {
  const {
    allWpPage: { nodes },
  } = useStaticQuery(graphql`
    {
      allWpPage(filter: { slug: { nin: ["home"] } }, sort: { fields: menuOrder }) {
        nodes {
          id
          slug
          title
          parentId
          menuOrder
        }
      }
    }
  `);

  let pages = keyBy(
    nodes.filter(n => !n.parentId).map(n => ({ ...n, children: [] })),
    'id'
  );
  nodes
    .filter(n => n.parentId)
    .forEach(n => {
      pages[n.parentId].children.push(n);
    });

  pages = [
    ...Object.values(pages).sort((a, b) => a.menuOrder - b.menuOrder),
    { slug: 'news', title: 'News', children: [] },
    { slug: 'research', title: 'Research', children: [] },
  ];

  return (
    <Grid.Row className="header">
      <Grid.Column
        floated="left"
        mobile={14}
        computer={6}
        style={{ position: 'relative', top: -12 }}
      >
        <Link to="/">
          <Header as="h1">
            <img src={Logo} className="logo" alt="HistoryCity Logo" />
            {siteTitle}
          </Header>
        </Link>
      </Grid.Column>
      <Grid.Column
        floated="right"
        className="nav desktop"
        computer={10}
        style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '40px' }}
      >
        {pages.map(p => (
          <div key={p.slug} className="menu">
            <Link to={`/${p.slug}`} className={`${p.children.length > 0 ? 'parent' : ''}`}>
              {p.title}
            </Link>
            {p.children.length > 0 && (
              <div className="child">
                {p.children.map(c => (
                  <Link key={c.slug} to={`/${c.slug}`}>
                    {c.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </Grid.Column>
      <Grid.Column floated="right" mobile={2} className="nav mobile">
        <Dropdown icon="bars" className="icon">
          <Dropdown.Menu direction="left" style={{ zIndex: 99 }}>
            {pages.map(p => (
              <>
                <Dropdown.Item key={p.slug}>
                  <Link to={`/${p.slug}`}>{p.title}</Link>
                </Dropdown.Item>
                {p.children.length > 0 && (
                  <>
                    {p.children.map(c => (
                      <Dropdown.Item key={c.slug}>
                        <Link to={`/${c.slug}`}>{c.title}</Link>
                      </Dropdown.Item>
                    ))}
                  </>
                )}
              </>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Column>
    </Grid.Row>
  );
};

TopHeader.propTypes = {
  siteTitle: PropTypes.string,
};

TopHeader.defaultProps = {
  siteTitle: '',
};

export default TopHeader;
